import { API_AUTH_LOGIN, API_USER_REGISTER } from '@lib/api-routes'

interface APIUserLoginTypes {
    email: string
    password: string
    siteId: string
}

export const APIUserLogin = async (body: APIUserLoginTypes) => {
    try {
        const response = await fetch(
            `${process.env.API_URL}${API_AUTH_LOGIN}`,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            // Capture error details from Next.js API response
            const errorData = await response.json(); // This should contain { errors: "..." }
            throw new Error(errorData.errors || `HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        localStorage.setItem('token', data.token);
    
        return { success: true, response: data };
    } catch (error) {
        console.error(error);
    
        return {
            success: false,
            error: error.message, // Will contain the custom message if available
        };
    }
}

interface APIUserRegisterTypes {
    siteId: string
    username: string
    email: string
    password: string
    name: string
    age: string
    gender: string
    location: string
    referer: string
}

export const APIUserRegister = async (body: APIUserRegisterTypes) => {
    try {
        const response = await fetch(
            `${process.env.API_URL}${API_USER_REGISTER}`,
            {
                method: 'POST',
                body: JSON.stringify(body),
            }
        )

        const data = await response.json()

        if (!response.ok) {
            return { success: false, error: data.errors, status: response.status }
        }

        return { success: true, response: data, status: response.status }
    } catch (error) {
        console.error(error)

        return {
            success: false,
            error: error
        }
    }
}
